.print-catch-report {
  .print-catch-report-parent-table {
    td.nested-table {
      padding: 0px !important;
    }

    td {
      vertical-align: middle;
      padding: 0.175rem 0.25rem !important;
    }

    table {
      margin: 0px !important;
    }

    .pcr-table-grid {
      width: 3rem;
    }

    .pcr-table-lifted {
      width: 4rem;
    }

    .pcr-table-depth {
      width: 5rem;
    }

    .pcr-table-species {
      width: 7rem;
    }

    .pcr-table-weight {
      width: 5rem;
    }

    .pcr-table-discarded {
      width: 6rem;
    }

    .pcr-table-condition {
      width: 6rem;
    }
  }
}
