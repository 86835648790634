.mobileDailyTripsOverlay {
  width: 100vw !important;
}

.modal-content {
  border: none !important;
}

.mobileDailyTripCloseMenu {
  box-shadow: 0 -0.5rem 2rem rgba($color: #000000, $alpha: 0.5);
}

.offcanvas {
  z-index: 1200 !important;
}

.offcanvas-backdrop {
  z-index: 1100 !important;
}

.addNewVesselOffCanvas {
  height: fit-content !important;
}

@media (max-width: 992px) {
  .daily-trip-modal {
    max-width: 580px !important;
  }
}

@media only screen and (max-width: 768px) {
  .daily-trip-modal,
  .submit-catch-report-modal,
  .daily-trip-modal {
    width: 100% !important;
    max-width: 100% !important;
    margin: 0 !important;

    .modal-content {
      height: 100% !important;
      min-height: 100vh !important;
    }

    .modal-header,
    .modal-content,
    .modal-footer {
      border-radius: 0;
    }
  }

  .dailyTripFooter {
    position: sticky !important;
  }
}

.offcanvas .btn-close {
  @extend .border, .border-primary;
  border-width: 2px !important;
}
