// Variable Over rides

$body-bg: #efefef;
$body-color: #343a40;

:root {
  --primary-color: #074a81;
  --secondary-color: #4498e0;
  --success-color: #0db9a9;
  --warning-color: #fabe49;
  --danger-color: #d52b28;
  --info-color: #4498e0;
  --light-color: #ffffff;
  --light-gray-color: #dbe2ef;
  --gray-color: #b0b6ba;
  --dark-color: #343a40;
  --background-color: #efefef;
}

$primary-color: #074a81;
$secondary-color: #4498e0;
$success-color: #0db9a9;
$warning-color: #fabe49;
$edited-color: #ffdf9f;
$danger-color: #d52b28;
$info-color: #4498e0;
$light-color: #ffffff;
$light-gray-color: #dbe2ef;
$gray-color: #b0b6ba;
$dark-color: #343a40;
$background-color: #efefef;

$theme-colors: (
  "primary": $primary-color,
  "secondary": $secondary-color,
  "success": $success-color,
  "warning": $warning-color,
  "danger": $danger-color,
  "info": $info-color,
  "light": $light-color,
  "light-gray": $light-gray-color,
  "gray": $gray-color,
  "dark": $dark-color,
  "background": $background-color,
);

$font-family-sans-serif: "Source Sans Pro", system-ui,
  // Safari for macOS and iOS (San Francisco)
  -apple-system,
  // Windows
  "Segoe UI",
  // Android
  Roboto,
  // Basic web fallback
  "Helvetica Neue",
  Arial,
  // Linux
  "Noto Sans",
  "Liberation Sans",
  // Sans serif fallback
  sans-serif,
  // Emoji fonts
  "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

$headings-font-weight: 700;
$lead-font-weight: 400;
$link-color: $primary-color;
$hr-color: $primary-color;

.isEditing .bg-edited {
  background-color: $edited-color;
}
