.hero-terms-list {
  background-color: rgba(7, 57, 99, 0.8);
}

.submitted-catch-reports-accordion {
  .accordion-item,
  .accordion-button {
    border-radius: 0px !important;
    border: none !important;
  }
}
