.alert button.btn-close {
  background-color: transparent !important;
  box-shadow: none !important;
}

.progress {
  background-color: var(--light-gray-color) !important;
}

.card-header {
  background-color: var(--primary-color) !important;
  color: white !important;
}

@media print {
  .print-container {
    max-width: unset !important;
  }
}
