// Today
.rmdp-day.rmdp-today span {
  background-color: var(--primary-color);
}

.multi-month-report-calendar {
  // Remove the selected day highlight
  .rmdp-day.rmdp-selected span:not(.highlight) {
    color: inherit;
    box-shadow: inherit;
    background-color: inherit;
  }

  .calendar-bg-circle {
    border-radius: 50%;
    bottom: 3px;
    font-size: 14px;
    left: 3px;
    position: absolute;
    right: 3px;
    top: 3px;
  }

  .span {
    @extend .position-relative;
  }

  span.catch-due-date {
    @extend .calendar-bg-circle;
    background-color: var(--danger-color);
    color: var(--light-color) !important;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }

  span.next-catch-due-date {
    @extend .calendar-bg-circle;
    background-color: var(--danger-color);
    color: var(--light-color) !important;
  }

  span.prev-catch-due-date {
    @extend .calendar-bg-circle;
    border: solid 2px var(--light-gray-color);
  }

  span.cal-daily-trip {
    @extend .bg-secondary, .text-light;
  }

  span.cal-incomplete-daily-trip {
    @extend .bg-warning, .text-light;
  }

  // Mobile Styles
  @media (max-width: 991.98px) {
    .rmdp-day-picker > div,
    .rmdp-top-class,
    .rmdp-calendar {
      width: 100%;
      margin: 0.25rem;

      span {
        font-size: 1rem;
        padding: 16px auto;
      }
    }
  }

  .rmdp-day {
    height: unset;
    width: 100%;
    aspect-ratio: 1;
  }
}

.tripDateCalendar {
  @extend .rounded, .w-100, .p-0, .p-lg-4;
  box-shadow: none;
  max-width: 480px;

  .rmdp-calendar,
  .rmdp-day-picker,
  .rmdp-day-picker div {
    @extend .w-100;
    // max-width: 600px;
  }

  .rmdp-week-day,
  .rmdp-header {
    // @extend .fw-light;
    color: var(--primary-color);
    font-size: calc(1.275rem + 0.1vw);
  }

  .rmdp-day,
  .rmdp-week-day {
    aspect-ratio: 1 / 1 !important;
    height: unset;
    span {
      @extend .fw-light;
      font-size: calc(1.275rem + 0.1vw);
    }
  }

  .rmdp-day.rmdp-today span {
    background: none;
    font-weight: bold !important;
    color: inherit;
  }

  .rmdp-day.rmdp-selected span:not(.highlight) {
    background-color: var(--secondary-color);
    color: var(--light-color);
  }

  .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
    @extend .border, .border-primary;
    background: none;
    color: inherit;
  }
}
