$table-bg: #fff;

table {
  thead {
    border-bottom: solid 1px var(--primary-color);

    tr th {
      background: none !important;
    }
  }

  tbody {
    tr td {
      padding: 1rem 0.5rem !important;
    }
  }
}

.table-header {
  .formSelectOrganization {
    @extend .me-2, .d-inline-block;

    label {
      @extend .d-none;
    }
  }
}

.tooltip {
  pointer-events: none !important;
}

.trips-table {
  &.daily-trips-table {
    th:last-child,
    td:last-child {
      .users-table {
        opacity: 0%;
      }

      //background: none;
      right: -1px;

      // &::before {
      //   content: "";
      //   position: absolute;
      //   left: 0;
      //   top: 0;
      //   height: 100%;
      //   // border-left: solid 1px var(--light-gray-color);
      // }
    }

    tr:hover td:last-child {
      // @extend .d-block;
      @extend .position-sticky;

      .users-table {
        opacity: 100%;
      }
    }
  }

  th:last-child {
    background-color: var(--light-color) !important;
  }

  th,
  td {
    white-space: nowrap;
    vertical-align: middle;
  }

  tbody {
    tr td {
      padding: 0.75rem 1rem !important;
    }
  }
}
