$btn-border-width: 2px;

button {
  @extend .shadow-sm;
}

.btn:disabled {
  cursor: not-allowed !important;
  pointer-events: all !important;
}

.btn-success,
.btn-secondary {
  color: $light-color !important;
}

.nav-btn .btn {
  background: none !important;
  box-shadow: none !important;
  border: none !important;

  &:hover {
    opacity: 0.66;
    background: none !important;
    box-shadow: none !important;
    border: none !important;
  }

  &:hover svg {
    opacity: 0.8;
  }
}

.btn-outline-primary-light {
  @extend .btn-outline-primary;

  &:hover {
    color: $primary-color !important;
    background-color: rgba(7, 14, 129, 0.1) !important;
  }
}

.btn-background {
  &:hover {
    background-color: $light-gray-color !important;
  }
}

.btn-outline-gray {
  &:hover {
    background-color: $secondary-color !important;
    border-color: $secondary-color !important;

    * {
      color: $light-color;
    }
  }
}

@media only screen and (max-width: 768px) {
  .catchReportBtn .dropdown-menu {
    width: 100%;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;

    .dropdown-item {
      padding: 0;
      padding-bottom: 1rem;
      margin: 1rem;
      width: auto;
      border-bottom: solid $gray-color 1px;

      &:last-child {
        // border-bottom: none;
      }
    }
  }
}
