// @import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";

// Utilities
$enable-negative-margins: true;

.nav-logo {
  user-select: none;
}

#version {
  cursor: help;
}

.link-unstyled {
  &,
  &:visited,
  &:hover,
  &:active,
  &:focus,
  &:active:hover {
    font-style: inherit;
    color: inherit;
    background-color: transparent;
    font-size: inherit;
    text-decoration: none;
    font-variant: inherit;
    font-weight: inherit;
    line-height: inherit;
    font-family: inherit;
    border-radius: inherit;
    border: inherit;
    outline: inherit;
    box-shadow: inherit;
  }
}

.link {
  &,
  &:hover,
  &:visited,
  &:active,
  &:focus,
  &:active:hover {
    @extend a;
    cursor: pointer;
  }
}

.cursor-pointer {
  &,
  &:hover,
  &:visited,
  &:active,
  &:focus,
  &:active:hover {
    cursor: pointer;
  }
}

@media only screen and (max-width: 575px) {
  .fw-md-normal {
    font-weight: bold;
  }
}

.form-btn {
  margin-top: 2rem;
}

.modal-content .btn-close {
  @extend .bg-light;
}

.text-small {
  font-size: 0.75rem;
}
// Breakpoints

// Small
@media only screen and (min-width: 576px) {
  .form-btn-sm {
    margin-top: 2rem !important;
  }
}

// Medium
@media only screen and (min-width: 768px) {
  .form-btn-md {
    margin-top: 2rem !important;
  }
}

// Large
@media only screen and (min-width: 992px) {
  .form-btn-lg {
    margin-top: 2rem !important;
  }
}

// Extra Large
@media only screen and (min-width: 1200px) {
  .form-btn-xl {
    margin-top: 2rem !important;
  }
}

.nav-tabs.fishing-type {
  @extend .mb-5;

  button {
    @extend .border-light-gray;
    border-radius: 0px !important;

    &[aria-selected="true"] {
      background-color: var(--light-color) !important;
      @extend .fw-bolder;
    }
  }

  li:first-of-type button {
    border-radius: 6px 0 0 6px !important;
  }

  &:last-of-type button {
    border-radius: 0 6px 6px 0 !important;
  }
}

.opacity-1 {
  opacity: 0.1;
}

.opacity-2 {
  opacity: 0.2;
}

.opacity-3 {
  opacity: 0.3;
}

.opacity-4 {
  opacity: 0.4;
}

.opacity-5 {
  opacity: 0.5;
}

.opacity-6 {
  opacity: 0.6;
}

.opacity-7 {
  opacity: 0.7;
}

.opacity-8 {
  opacity: 0.8;
}

.opacity-9 {
  opacity: 0.9;
}

.video-container {
  @extend .mb-3;
  position: relative;
  padding-bottom: 56.25%;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video {
  aspect-ratio: 16 / 9;
  width: 100%;
}

$sizes: (
  auto: auto,
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%,
);

$positions: (
  static: static,
  relative: relative,
  absolute: absolute,
  fixed: fixed,
  sticky: sticky,
);

@each $breakpoint in map-keys($grid-breakpoints) {
  @each $position, $value in $positions {
    @include media-breakpoint-up($breakpoint) {
      .position-#{$breakpoint}-#{$position} {
        position: $value !important;
      }
    }
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @each $size, $length in $sizes {
    @include media-breakpoint-up($breakpoint) {
      .w-#{$breakpoint}-#{$size} {
        width: $length !important;
      }
    }
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .border#{$infix}-top {
      border-top: $border-width solid $border-color !important;
    }
    .border#{$infix}-right {
      border-right: $border-width solid $border-color !important;
    }
    .border#{$infix}-bottom {
      border-bottom: $border-width solid $border-color !important;
    }
    .border#{$infix}-left {
      border-left: $border-width solid $border-color !important;
    }

    .border#{$infix}-top-0 {
      border-top: 0 !important;
    }
    .border#{$infix}-right-0 {
      border-right: 0 !important;
    }
    .border#{$infix}-bottom-0 {
      border-bottom: 0 !important;
    }
    .border#{$infix}-left-0 {
      border-left: 0 !important;
    }

    .border#{$infix}-x {
      border-left: $border-width solid $border-color !important;
      border-right: $border-width solid $border-color !important;
    }

    .border#{$infix}-y {
      border-top: $border-width solid $border-color !important;
      border-bottom: $border-width solid $border-color !important;
    }

    .border#{$infix}-x-0 {
      border-left: 0 !important;
      border-right: 0 !important;
    }

    .border#{$infix}-y-0 {
      border-top: 0 !important;
      border-bottom: 0 !important;
    }
  }
}

.z-10 {
  z-index: 10;
}

.z-100 {
  z-index: 100;
}

.z-1000 {
  z-index: 1000;
}
