// Input
$input-bg: $light-color;
$input-border-color: $light-gray-color;
$input-border-width: 2px;

$form-select-border-width: 2px;

// Focus
$form-select-focus-width: 0px;
$input-focus-box-shadow: none;

.form-card {
  @extend .p-3, .bg-white, .rounded, .shadow-sm;
  margin-bottom: 3rem;
}

.form-card-gray {
  @extend .p-3, .mb-3, .bg-light-gray, .rounded, .shadow-sm;
}

a .dashboard-card h2 {
  text-decoration: none !important;
}

.dashboard-card {
  @extend .form-card, .text-center, .py-5;
  border: 1px solid var(--primary-color);
  margin-bottom: 1.5rem;

  .dashboard-icon {
    @extend .fs-1, .text-primary, .mb-4;
  }

  h2 {
    @extend .fw-normal, .text-primary, .text-center, .mb-0;
    text-decoration: none !important;
  }

  &:hover {
    @extend .bg-primary;
    .dashboard-icon,
    h2 {
      @extend .text-light;
    }
  }
}

.form-header {
  @extend .lead;
}

.callout-primary {
  border-left: 0.5rem solid var(--primary-color);
}

.callout-secondary {
  border-left: 0.5rem solid var(--secondary-color);
}

.callout-success {
  border-left: 0.5rem solid var(--success-color);
}

.callout-warning {
  border-left: 0.5rem solid var(--warning-color);
}

select.readOnly.form-select {
  background-color: var(--light-color) !important;
  padding: 0 !important;
  border: 0rem var(--light-color) solid !important;
  background-image: none !important;
}

// Large
@media only screen and (max-width: 991px) {
  input.form-control-plaintext {
    padding: 0 !important;
  }
}

// Select Vessel
.select-vessel {
  input {
    display: none;
  }

  .form-check {
    padding-left: 0;
  }

  input[type="radio"]:checked + label {
    background-color: var(--primary-color) !important;
    color: var(--light-color) !important;

    svg {
      color: var(--light-color) !important;
    }
  }
}

label {
  @extend .mb-1;
  font-weight: 600;
  font-size: 0.9rem;
}

.accordion-button {
  @extend .fw-bold;
  background-color: var(--light-color) !important;
}

.accordion-button:not(.collapsed) {
  background-color: var(--primary-color) !important;
  color: var(--light-color) !important;
}

.accordion-collapse {
  @extend .bg-light;
}

// Fish Caught

.fish-per-grid-rows .row:nth-child(odd) {
  @extend .bg-background;
}

.fish-per-grid-rows .row > * {
  @extend .d-flex, .align-items-center;
}

input#manualGridFeature {
  min-width: 5rem;

  &:focus + .sibling-input-border {
    border-color: rgb(134, 183, 254) !important;
  }
}

.sibling-input-border {
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

// Form Button
.form-button {
  @extend .p-2,
    .mb-2,
    .bg-white,
    .border,
    .border-primary,
    .rounded,
    .shadow-sm,
    .text-center,
    .d-flex,
    .flex-column,
    .justify-content-center,
    .align-items-center,
    .cursor-pointer;

  // width: 8rem;
  // height: 8rem;
  aspect-ratio: 1/1;
  transition: all 0.3s ease;

  &:hover {
    @extend .bg-background;
  }

  svg {
    @extend .text-primary, .mb-2;
    display: block;
    font-size: 2.5rem;
  }

  strong {
    line-height: 1rem;
    min-height: 2rem;
  }

  &.selected {
    @extend .bg-primary, .text-light;

    svg {
      @extend .text-light;
    }

    &:hover {
      background-color: #063f6e !important;
    }
  }

  &.loading {
    @extend .bg-background;
  }
}

.form-button-small {
  @extend .p-3,
    .bg-white,
    .border,
    .border-primary,
    .rounded,
    .shadow-sm,
    .d-flex,
    .align-items-center,
    .cursor-pointer;
  height: 4rem;
  transition: all 0.3s ease;

  &:hover {
    @extend .bg-background;
  }

  svg {
    @extend .text-primary, .me-2;
    min-width: 2rem;
    min-height: 2rem;
  }

  strong {
    line-height: 1rem;
  }
}

.form-button-list {
  @extend .form-card,
    .p-4,
    .position-absolute,
    .shadow,
    .border,
    .border-primary,
    .my-3,
    .w-100;
  min-height: 100%;
  z-index: 10;

  // &:before {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   margin-top: -30px;
  //   right: var(--buttonOffset);
  //   border: solid 15px transparent;
  //   border-left-color: var(--gray-color);
  //   transform: rotate(-90deg);
  //   z-index: 11;
  // }
  // &:after {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   margin-top: -28px;
  //   right: var(--buttonOffset);
  //   border: solid 15px transparent;
  //   border-left-color: var(--light-color);
  //   transform: rotate(-90deg);
  //   z-index: 12;
  // }
}

.form-select {
  @extend .bg-light;
}

.btn-group-secondary {
  .btn {
    @extend .text-dark, .bg-light;
  }

  .btn-check:checked + .btn {
    @extend .text-light, .bg-secondary, .fw-bold;
  }
}
